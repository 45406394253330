.card {
  position: absolute;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  will-change: transform;

}

.emgo-background {
  background-image: url(/images/emgo-background.jpg);

  width: 100%;
  height: 100vh;
}

.emgo-logo-building {
  background-image: url(/images/emgo-logo-building.png);
  width:90ch;
  height:80ch;
}

.car1 {
  background-image: url(/images/car1.png);
  width:20ch;
  height:30ch;
}

.car2 {
  background-image: url(/images/car2.png);
  width:40ch;
  height:30ch;
}

.car3 {
  background-image: url(/images/car3.png);
  width:25ch;
  height:30ch;
}

.peeps {
  background-image: url(/images/peeps-emgo.png);
  width:25ch;
  height:25ch;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}
#emgo-title {
  color: white;
  font-size: 3rem;
  position: absolute;
  padding-top: 6px;
  padding-left: 6px;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: .5;
}

@media only screen and (max-width: 600px) {
  #emgo-title {
    width: 100%;
    text-align: center;
    font-size: 2rem;
    padding: 0;
  }
}

.social-icon-img {
  height: 32px;
  width: 32px;
  padding-right: 16px;
  opacity: .7;
  transition: opacity .5s;
}

.social-icon-img:hover {
  opacity: 1;
}

#bg-image {
  height: 100%;
  background-image: url(/images/emgo-bg.jpg);
  background-size: cover;
  background-position: center;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  transition: filter .5s;
}

.emailForm {
  color: #f00;
  z-index: 2;
  -webkit-transition: opacity 0.25s;
  -moz-transition: opacity 0.25s;
  transition: opacity .25s;
  opacity: 0;
  /* -webkit-transform:translate3d(0,0,200px); */
  position: absolute;
  /* border: 1px solid #fff; */
  /* background-color: #00C; */
  border: none;
  display: flex;
  flex-direction: column;
  padding: 40px;
}

.emailForm > input {
  /* border: none; */
  border: 1px solid #fff;
  background-color: transparent;
  color: white;
  margin-bottom: 10px;
  font-size: 1.5rem;
  padding: .5rem;
}

.emailForm > input::placeholder {
  color: #ccc7;
  text-align: center;
}

.emailForm > button {
  /* border: none; */
  border: 1px solid #fff;
  background-color: transparent;
  color: white;
  margin-top: 10px;
  font-size: 1rem;
  padding: .5rem;
  transition: background-color .25s ease-in-out;
  cursor: pointer;
}

.emailForm > button:hover {
  background-color: #fff4;

}